import { Controller } from 'stimulus';
import is from 'is_js';

export default class extends Controller {
  static targets = ['cover', 'whiteContent'];

  connect() {
    setTimeout(() => {
      this.coverTarget.classList.add('hidden');
    }, 500);

    if (is.safari() && this.hasWhiteContentTarget) {
      this.whiteContentTarget.classList.add('white-text-on-safari');
    }
  }
}
